import React from 'react';
import { characterMap } from '../lib/characterMap';
import Character from '../components/character';

const KillikbarBio = () => {
  const data = characterMap['Killikbar'];
  return (
    <Character
      name='Killikbar'
      bio={data.bio}
      imagePath={data.imageUrl}
      title={data.title}
      fullTitle={data.fullTitle}
      position={data.position}
      navNext={data.navNext}
      navPrevious={data.navPrevious}
    />
  )
}

export default KillikbarBio;
